import React from 'react'
import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

interface Props {
  collaborators: { _key: string; title?: string; href?: string }[] | undefined
  fluid?: boolean
}

export default function Collaborators({ collaborators, fluid }: Props) {
  if (!collaborators || collaborators.length === 0) {
    return null
  }

  return (
    <span {...bem('', { fluid })}>
      I samarbeid med{' '}
      {collaborators.map((collaborator, index) => {
        if (!collaborator.title) {
          return null
        }
        let separator = ''
        if (collaborators.length === 2 && index === 0) {
          separator = ' og '
        } else if (collaborators.length > 2) {
          // if second to last, print " og "
          if (index === collaborators.length - 2) {
            separator = ' og '
          }
          // if not last, print ", "
          else if (index !== collaborators.length - 1) {
            separator = ', '
          }
        }
        if (collaborator.href) {
          return (
            <React.Fragment key={collaborator._key}>
              <a href={collaborator.href} target="blank" rel="noopener noreferrer">
                {collaborator.title}
              </a>
              {separator}
            </React.Fragment>
          )
        }
        return (
          <React.Fragment key={collaborator._key}>
            {collaborator.title}
            {separator}
          </React.Fragment>
        )
      })}
    </span>
  )
}
