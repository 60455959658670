import React from 'react'
import T from 'prop-types'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
import Button from '../Button'
import getUrl, { LINK_TYPES, FILE_TYPES } from '../../helpers/links'
import { useRouter } from 'next/router'

const bem = new BEMHelper(styles)

export default function Assets({ assets, subtle, className }) {
  const router = useRouter()

  return (
    <div {...bem('', null, className)}>
      {assets.map(({ _key, _type, title, entry, url, asset }) => {
        switch (_type) {
          case 'externalResource':
            return (
              <Button
                {...bem('item')}
                primary={!subtle}
                small={subtle}
                left
                href={url}
                key={_key}
                label={`Link: ${title}`}
                icon="external"
                target="_blank"
                rel="noopener noreferrer"
              />
            )

          case 'internalResource':
            return entry ? (
              <Button
                {...bem('item')}
                primary={!subtle}
                small={subtle}
                left
                href={{
                  pathname: getUrl(entry?._type, entry?.slug),
                  query: { referer: router.asPath },
                }}
                key={_key}
                label={`${LINK_TYPES[entry?._type]}: ${entry?.title}`}
                icon="chevron"
              />
            ) : null

          case 'file':
          case 'resource':
            return asset ? (
              <Button
                {...bem('item')}
                primary={!subtle}
                small={subtle}
                left
                href={asset.url}
                key={_key}
                target="_blank"
                rel="noopener noreferrer"
                label={`Last ned: ${title} (${FILE_TYPES[asset.extension] || asset.extension})`}
                icon="download"
              />
            ) : null
        }

        console.warn('Unknown asset type: ', _type)

        return null
      })}
    </div>
  )
}

Assets.propTypes = {
  className: T.string,
  subtle: T.bool,
  assets: T.arrayOf(
    T.shape({
      _type: T.string,
      title: T.string,
      entry: T.object,
      url: T.string,
      asset: T.object,
    }),
  ),
}
