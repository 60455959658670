import BlockContent from '@sanity/block-content-to-react'
import T from 'prop-types'

import { dataset, projectId } from '../../api/sanity'
import BEMHelper from '../../helpers/bem'
import blockContentSerializers from '../../helpers/block-content-serializers'
import Accordion from '../Accordion'
import Assets from '../Assets'
import Image from '../Image'
import Tips from '../Tips'
import { VideoPlayer } from '../VideoPlayer'
import YouTube from '../YouTube'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

export function ContentSection({
  _key,
  mainSectionId,
  active,
  title,
  extra,
  text,
  assets,
  media,
  type,
}) {
  const tips = extra?.find(({ _type }) => _type === 'tips')
  const accordions = extra?.filter(({ _type }) => _type === 'accordion')
  const image = media && media?.[0]?._type === 'image' ? media?.[0] : null
  const youtube = media && media?.[0]?._type === 'youtube' || media?.[0]?.videoType === 'youtube' ? media?.[0] : null
  const muxVideo =
    media && (media?.[0]?._type === 'muxVideo' || media?.[0]?.videoType === 'internal') ? media?.[0] : null
  const hasMedia = !!(image || youtube || muxVideo)

  return (
    <section
      key={_key}
      {...bem('section', {
        active: active === _key,
        media: hasMedia || tips,
        [type]: type,
      })}
      data-main-id={mainSectionId}
      id={_key}
    >
      <div {...bem('content')}>
        {title && <h2 {...bem('sub-title')}>{title}</h2>}

        {text && (
          <BlockContent
            renderContainerOnSingleChild
            {...bem('text')}
            blocks={text}
            serializers={blockContentSerializers}
            dataset={dataset}
            projectId={projectId}
          />
        )}

        {assets?.length > 0 && <Assets assets={assets} {...bem('assets')} />}

        {accordions?.length > 0 && (
          <div {...bem('accordions')}>
            {accordions.map(({ _key, ...item }) => (
              <Accordion key={_key} title={item.title ?? ''} {...item} />
            ))}
          </div>
        )}
      </div>
      {image && <Image {...image} {...bem('media', media[0]?.type)} expandable alt={''} />}
      {youtube && <YouTube {...youtube} {...bem('media', media[0]?.type)} />}
      {muxVideo && (
        <div {...bem('media')}>
          <VideoPlayer
            playbackId={muxVideo?.video?.asset?.playbackId ?? ''}
            caption={muxVideo?.caption ?? ''}
            title={muxVideo?.title ?? ''}
            aspectRatio={muxVideo?.aspectRatio ?? ''}
          />
        </div>
      )}
      {tips && <Tips {...bem('tips', { left: hasMedia })}>{tips.content}</Tips>}
    </section>
  )
}

export default function Content({ data, active }) {
  return (
    <div {...bem('')}>
      {(data || [])

        // map all the main sections to an list of sections
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ?.map(({ _key, sections = [] }: { _key: string; sections: Array<any> }) => [
          // the content sections within the main section
          ...(sections?.map((section) => (
            <ContentSection
              active={active.activeSection}
              key={section._key}
              mainSectionId={_key}
              {...section}
            />
          )) || []),
        ])

        // flatten the structure to get a single level array of elements
        .reduce((res, sections) => [...res, ...sections], [])}
    </div>
  )
}

Content.propTypes = {
  data: T.array.isRequired,
  active: T.shape({
    activeSection: T.string,
    activeMainSection: T.string,
  }),
}

ContentSection.propTypes = {
  _key: T.string,
  mainSectionId: T.string,
  active: T.string,
  title: T.string,
  extra: T.arrayOf(
    T.shape({
      _type: T.string.isRequired,
    }),
  ),
  text: T.array,
  assets: T.array,
  media: T.array,
  type: T.string,
}
