import React from 'react'
import T from 'prop-types'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

export default function Tips({ title, children, className, type }) {
  return (
    <aside {...bem('', type, className)}>
      <h3 {...bem('title')}>{title || 'Tips'}</h3>
      <p {...bem('text')}>{children}</p>
    </aside>
  )
}

Tips.propTypes = {
  title: T.string,
  children: T.any.isRequired,
  className: T.string,
  type: T.string,
}
